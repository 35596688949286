<template>
  <div>
    <v-divider></v-divider>
    <v-data-table
      :footer-props="{
        'items-per-page-text': $vuetify.breakpoint.smAndDown
          ? ''
          : $t('items_per_page'),
      }"
      :items="items"
      :headers="headers"
      :options.sync="options"
      :server-items-length="total"
    >
      <template v-slot:item.price="{ item }">{{
        $n(item.appointments[0].price, "currency")
      }}</template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TattooerReport",
  data() {
    return {
      items: [],
      headers: [
        {
          text: this.$t("first_name"),
          sortable: false,
          value: "user.first_name",
        },
        { text: this.$t("email"), sortable: false, value: "user.email" },
        {
          text: this.$tc("appointment"),
          sortable: false,
          value: "appointments[0].total",
        },
        {
          text: this.$t("appointments.price"),
          sortable: false,
          value: "price",
        },
      ],
      options: {},
      total: null,
    };
  },
  methods: {
    ...mapActions("reports", ["getTattooersReport"]),
    fetchTattooerReport() {
      this.getTattooersReport({ options: this.options }).then((data) => {
        this.items = data.data;
        this.total = data.total;
      });
    },
  },
  mounted() {
    this.fetchTattooerReport();
  },
  watch: {
    options: {
      handler() {
        this.fetchTattooerReport();
      },
      deep: true,
    },
  },
};
</script>